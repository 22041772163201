let userName = name ??  "";
let userEmail = email ?? "";

$(function () {
    $('#contact').click(() => {
        let windowTitle = 'Contact Us';
        let alertClass = 'alert-info';
        let alertMessage = '<strong>Got a Question?</strong> Please complete the information below to send us a message and we\'ll get back to you as soon as possible.';
        let bodyMessage = `<form class="validate" id="contactForm">
                <div class="form-group row">
                    <label for="contactName" class="col-sm-2 col-form-label"><span style="color: red;">*</span>Name:</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="contactName" placeholder="Name" value="${userName}" 
                            required 
                            minlength="2" 
                            maxlength="75"
                            data-parsley-trigger="focusout"
                            data-parsley-whitespace="squish"
                            data-parsley-required-message="Please enter your name"
                            data-parsley-pattern-message="Please enter your full name (e.g. First Last)"
                            data-parsley-custom-name>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="contactEmail" class="col-sm-2 col-form-label"><span style="color: red;">*</span>Email:</label>
                    <div class="col-sm-10">
                        <input type="email" class="form-control" id="contactEmail" placeholder="Email" value="${userEmail}" 
                            required
                            data-parsley-trigger="focusout"
                            data-parsley-required-message="Please enter your email address"
                            data-parsley-email-message="Please enter a valid email address">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="contactMessage" class="col-sm-2 col-form-label"><span style="color: red;">*</span>Message:</label>
                    <div class="col-sm-10">
                        <textarea class="form-control" id="contactMessage" rows="3" 
                            required 
                            minlength="4" 
                            maxlength="250"
                            pattern="^[A-Za-z0-9!\-.,#%$()' ]+$"
                            data-parsley-trigger="focusout"
                            data-parsley-whitespace="squish"
                            data-parsley-required-message="Please leave us a message"
                            data-parsley-pattern-message="Please remove invalid characters: (!-.,#%$()' permitted)"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10 offset-sm-2">
                        <div id="captcha"></div>
                    </div>
                </div>
            </form>`;

        let footerMessage = '<button type="button" class="btn btn-sm btn-ncats gap-right" data-dismiss="modal">Close</button>' +
            '<button type="button" id="contactSubmitButton" class="btn btn-sm btn-ncats" disabled>Send message</button>';
        showModal(windowTitle, alertClass, alertMessage, bodyMessage, footerMessage);
        
        setTimeout(function () {
            createRecaptcha();
        }, 100);

        let modal = $('#messageModal');
        let pageTitle = $("title").text();

        // Change page title on shown modal
        modal.on('shown.bs.modal', () => {
            $('title').text('RARe-SOURCE | Contact Us');
        });

        // Change page title back on hide modal
        modal.on('hidden.bs.modal', () => {
            $("title").text(pageTitle);
        });

        modal.one('shown.bs.modal', () => {
            let submitButton = $('#contactSubmitButton');

            submitButton.click(() => {
                $('#contactForm').submit();
            });

           $('#contactForm').parsley().on('form:submit', function() {
               submitContactMessage();
               return false;
           });
        });

        function submitContactMessage() {
            let name = $('#contactName').val();
            let email = $('#contactEmail').val();
            let message = $('#contactMessage').val();

            $.ajax({
                url      : '/contact/',
                type     : 'post',
                data     : {
                    'name'       : name,
                    'from_email' : email,
                    'message'    : message
                },
                dataType : 'json',
                success  : function (response) {
                    modal.modal('hide');

                    modal.one('hidden.bs.modal', () => {
                        let windowTitle = 'Contact Us';
                        let alertClass = (response.status) ? 'alert-info' : 'alert-danger';
                        let alertMessage = (response.status) ? 'Thank you.<br>We will review your message and get in touch soon.' : 'We\'re sorry, we were unable to send your message at this time. Please try again later or contact our team for assistance.';
                        let bodyMessage = '';
                        let footerMessage = '<button type="button" class="btn btn-sm btn-ncats" data-dismiss="modal">Close</button>';

                        showModal(windowTitle, alertClass, alertMessage, bodyMessage, footerMessage);
                    });
                }
            });

        }
    });
});

function createRecaptcha() {
    grecaptcha.render("captcha", {
        sitekey	: "6Lcv2LcUAAAAAMeSucnTgABmgsyvi7GKGKnzHkAT",
        theme    : "light",
        callback : validCaptcha
    });
}

let validCaptcha = function (response) {
    $('#contactSubmitButton').removeAttr("disabled");
};

window.Parsley.addValidator('customName', {
    requirementType: 'string',
    validateString: function(value, requirement) {
        let pattern = /^[A-Za-z.,' -]+$/;
        return pattern.test(value);
    },
    messages: {
        en: 'Please enter your full name (e.g. First Last)'
    }
});